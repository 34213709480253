import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons"

import BlogSideNavSimilarByTag from "@components/layout/blog/sideNav/sections/similarTag.js"
import BlogSideNavSeriesNode from "@components/layout/blog/sideNav/sections/series.js"
import BlogSideNavMysticApes from "@components/layout/blog/sideNav/sections/mysticApes.js"

import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

import Card from "@components/card/card.js"
import CardBody from "@components/card/cardBody.js"
// import CardFooter from "@components/card/cardFooter.js";
// import CardHeader from "@components/card/cardHeader.js";

BlogSideNav.propTypes = {
  props: PropTypes.object,
}

export default function BlogSideNav(props) {
  const { pageNode, classes, classesPost, className, location } = props
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation()

  const seriesNode = pageNode.seriesNode !== null ? pageNode.seriesNode : []
  const similarNodeByTag =
    pageNode.similarNodeByTag !== null ? pageNode.similarNodeByTag : []

  const [openCloseBlogSideNav, setOpenCloseBlogSideNav] = useState(
    faAngleDoubleLeft
  )
  const [sideNavOpen, setSideNavOpen] = useState(false)
  const [sideNavClasses, setSideNavClasses] = useState(
    GetSideNavClassesFunction()
  )

  function GetSideNavClassesFunction(forceSideNav){
    const localSideNavOpen =
      forceSideNav !== null && typeof forceSideNav !== "undefined"
        ? forceSideNav
        : sideNavOpen;
        
    return classNames({
      [classesPost.blogSideNav]: true,
      "open": localSideNavOpen,
      "close": !localSideNavOpen,
      [themeVariation]: true,
      [className]: className,
    })
  }

  function ProcessOpenClick() {
    setSideNavOpen(true)
    setSideNavClasses(GetSideNavClassesFunction(true))
    setOpenCloseBlogSideNav(faAngleDoubleRight)
  }
  function ProcessCloseClick() {
    setSideNavOpen(false)
    setSideNavClasses(GetSideNavClassesFunction(false))
    setOpenCloseBlogSideNav(faAngleDoubleLeft)
  }
  const ProcessOpenCloseClick = e => {
    if (!sideNavOpen) {
      return ProcessOpenClick()
    }

    return ProcessCloseClick()
  }

  const sideNavLinks = (
    <Card>
      <CardBody className={classes.cardBody}>
        <button className={`openClose`} onClick={ProcessOpenCloseClick}>
          <FontAwesomeIcon icon={openCloseBlogSideNav} />
        </button>
        <div>
          <FacebookShareButton
            hashtag={pageNode.postHash.join(" ")}
            url={`https://www.threemysticapes.com${pageNode.slug}`}
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton
            title={pageNode.context.title}
            source={`threemysticapes.com`}
            summary={pageNode.context.excerpt}
            url={`https://www.threemysticapes.com${pageNode.slug}`}
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton
            title={pageNode.context.title}
            hashtags={pageNode.postHash}
            url={`https://www.threemysticapes.com${pageNode.slug}`}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </div>
        <BlogSideNavSeriesNode
          pageNode={pageNode}
          location={location}
          navClasses={GetSideNavClassesFunction()}
          classes={classes}
          classesPost={classesPost}
        />
        <BlogSideNavSimilarByTag
          pageNode={pageNode}
          location={location}
          navClasses={GetSideNavClassesFunction()}
          classes={classes}
          classesPost={classesPost}
          excludeNodes={seriesNode.map(node => node.id)}
        />
        <BlogSideNavMysticApes
          pageNode={pageNode}
          location={location}
          navClasses={GetSideNavClassesFunction()}
          classes={classes}
          classesPost={classesPost}
          excludeNodes={seriesNode
            .map(node => node.id)
            .concat(similarNodeByTag.map(node => node.id))}
        />
      </CardBody>
    </Card>
  )

  return (
    <nav className={sideNavClasses}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={12}>
          {sideNavLinks}
        </GridItem>
      </GridContainer>
    </nav>
  )
}
