import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

//https://jsonld.com/blog-post/
BlogSchema.propTypes = {
  props: PropTypes.object,
}

export function BlogSchema(props) {
  const { site, siteLogo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
        siteLogo: file(
          relativePath: {
            eq: "assets_threemysticapes/image/global/threemysticapes_llc_white.png"
          }
        ) {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    `
  )
  const { pageNode } = props
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          { "@context": "https://schema.org", 
            "@type": "BlogPosting",
            "headline": ${JSON.stringify(pageNode.context.title)},
            "image": "${pageNode.context.hero.publicURL}",
            "url": "${site.siteMetadata.siteUrl}${pageNode.slug}",
            "keywords": "${pageNode.context.tags.join(",")}",
            "wordcount": "${pageNode.readingTime.words}",
            "datePublished": "${pageNode.context.created}",
            "dateCreated": "${pageNode.context.created}",
            "dateModified": "${pageNode.context.startDate}",
            "description": ${JSON.stringify(pageNode.context.excerpt)},
            "articleBody": ${JSON.stringify(pageNode.internal.content)},
            "publisher": {
              "@type": "Organization",
              "name": "3 Mystic Apes LLC",
              "logo": {
                "@type": "ImageObject",
                "url": "${site.siteMetadata.siteUrl}${
          siteLogo.childImageSharp.original.src
        }"
              }
            },
            "author": {
              "@type": "Organization",
              "name": "3 Mystic Apes LLC"
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${site.siteMetadata.siteUrl}${pageNode.slug}"
            }
          }
        `}
      </script>
    </Helmet>
  )
}
