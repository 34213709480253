//copy contents from assets/jss/common/material-kit-react.js
//Dark theme reference
//https://www.bootstrapdash.com/demo/material-admin-dark/jquery/template/demo_1/index.html
//material components
//https://material-components.github.io/


import React from "react"
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import "fontsource-roboto"
//@FontAwsome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

//templateComponents
import LazyHero from 'react-lazy-hero';
import LayoutMain from "@components/layout/layoutMain.js";
import PostHeroText from "@components/layout/section/common/blog/postHeroText.js";
import PageMaincontent from "@components/layout/section/common/page/mainContent.js";
import BlogSideNav from "@components/layout/blog/sideNav/side.js"
import { BlogComment } from "@components/layout/blog/post/comment.js"
import { BlogCommentList } from "@components/layout/blog/post/commentList.js"
import { BlogSchema } from "@components/layout/blog/post/schemaOrg.js"

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";

import { layoutPageStyle } from "assets/jss/views/layout/layout.js";
import { globalPageStyle } from "assets/jss/common/globalPage.js"
import { layoutPostStyle } from "assets/jss/template/blog/post.js";

import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js";

library.add(fab);

export const useLayoutStyles = makeStyles(layoutPageStyle)
export const usePageStyles = makeStyles(globalPageStyle)
export const usePostStyles = makeStyles(layoutPostStyle)

LayoutBlog.propTypes = {
  children: PropTypes.node.isRequired,
  pageNode: PropTypes.object.isRequired,
  props: PropTypes.object,
}


function GetHeroClassNames(pageNode, classes) {
  const heroClassNames = [classes.hero];
  if (pageNode.context.pageStyle === null) {
    return (heroClassNames)
  }

  if (pageNode.context.pageStyle[0].name === "hero") {
    if (pageNode.context.pageStyle[0].style === null) {
      return (heroClassNames)
    }
    return (heroClassNames.concat(pageNode.context.pageStyle[0].style));
  }
  for (let index = 0; index < pageNode.pageStyle.length; index++) {
    if (pageNode.context.pageStyle[index].name === "hero") {
      return (heroClassNames.concat(pageNode.context.pageStyle[index].style));
    }
  }

  return heroClassNames

}

export function LayoutBlog({ children, pageNode, location, pageContext, ...props }) {
  
  const classes = useLayoutStyles()
  const classesPage = usePageStyles()
  const classesPost = usePostStyles()

  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();
  if (typeof window !== 'undefined'){
    localStorage.setItem("themeVariation", themeVariation);
  }

 

  return (
    <LayoutMain
      pageNode={pageNode}
      classes={classes}
      classesPage={classesPage}
      classesPost={classesPost}
      location={location}
      pageContext={pageContext}
      {...props}
    >
      <BlogSchema
        pageNode={pageNode}
        classes={classes}
        classesPage={classesPage}
        classesPost={classesPost}
        location={location}
        pageContext={pageContext}
        {...props}
      />
      <LazyHero
        className={classNames(GetHeroClassNames(pageNode, classesPost))}
        imageSrc={
          pageNode.context.hero === null
            ? null
            : pageNode.context.hero.publicURL
        }
      >
        <div className={`heroText`}>
          <PostHeroText
            classes={classes}
            classesPage={classesPage}
            classesPost={classesPost}
            pageNode={pageNode}
          />
        </div>
      </LazyHero>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justify="center">
            <PageMaincontent
              pageNode={pageNode}
              classes={classes}
              classesPage={classesPage}
              classesPost={classesPost}
              location={location}
              pageContext={pageContext}
            >
              <div>
                {pageNode.readingTime.text}
                <br />
                <div>
                  {ThreeMysticApesUtil.FormatDate(
                    ThreeMysticApesUtil.GetDateTime(pageNode.context.startDate)
                  )}
                </div>
              </div>
              {children}
            </PageMaincontent>
            <BlogSideNav
              pageNode={pageNode}
              classes={classes}
              classesPage={classesPage}
              classesPost={classesPost}
              location={location}
            />
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <BlogCommentList
                pageNode={pageNode}
                classes={classes}
                classesPage={classesPage}
                classesPost={classesPost}
                location={location}
              />
            </GridItem>
            <GridItem xs={`auto`} sm={`auto`} md={6}>
              <BlogComment
                pageNode={pageNode}
                classes={classes}
                classesPage={classesPage}
                classesPost={classesPost}
                location={location}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </LayoutMain>
  )
}