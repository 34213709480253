import React from "react"
import axios from "axios"

// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"

// core components
import GoogleRecaptcha from "@components/layout/section/common/page/recaptcha.js"
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

import { H4 } from "@components/header/header.js"
import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"
import {
  FormControl,
  FormControlLabel,
  TextField,
  Switch,
  FormHelperText,
} from "@material-ui/core"
import Button from "@components/customButtons/button.js"
import Card from "@components/card/card.js"
import CardBody from "@components/card/cardBody.js"
import CardFooter from "@components/card/cardFooter.js"
import CardHeader from "@components/card/cardHeader.js"

import { useSnackbar } from "notistack"

BlogComment.propTypes = {
  props: PropTypes.object,
}

export function BlogComment(props) {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    componentKeys {
                        google {
                            recaptcha
                        }
                        staticman {
                            domain
                            endpoints {
                                entry
                            }
                        }
                    }
                }
            }
        }
    `
  )
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation()

  if (!props.pageNode.allowComments) {
    return null
  }

  const { classesPage } = props

  const recaptchaKey = site.siteMetadata.componentKeys.google.recaptcha
  const staticManSubmit =
    site.siteMetadata.componentKeys.staticman.domain +
    site.siteMetadata.componentKeys.staticman.endpoints.entry

  const commentFormData = {
    comment: ThreeMysticApesUtil.GeneralFormObject(
      "Please provide a comment",
      3,
      100
    ),
    agree: ThreeMysticApesUtil.GeneralFormObject(
      "You must agree to post a comment",
      0,
      0,
      {
        data: { presence: { allowEmpty: false }, type: "boolean" },
      }
    ),
    submit: {
      attribute: {
        disabled: ThreeMysticApesUtil.CreateStateData(false),
        text: ThreeMysticApesUtil.CreateStateData("Post Comment"),
      },
    },
    reset: function () {
      const keys = Object.keys(this)
      for (let index = 0; index < keys.length; index++) {
        if (
          this[keys[index]].validate === null ||
          typeof this[keys[index]].validate === "undefined" ||
          this[keys[index]].validate === undefined
        ) {
          continue
        }

        this[keys[index]].value.setData("")
        this[keys[index]].value.data = ""
      }
    },
    validate: function () {
      const keys = Object.keys(this)
      let validateObject = true
      for (let index = 0; index < keys.length; index++) {
        if (
          this[keys[index]].validate === null ||
          typeof this[keys[index]].validate === "undefined" ||
          this[keys[index]].validate === undefined
        ) {
          continue
        }

        if (this[keys[index]].validate() === false) {
          validateObject = false
          continue
        }
      }

      return validateObject
    },
  }

  function processInputChange(e, key) {
    if (e.target.type !== "checkbox") {
      commentFormData[key].value.setData(e.target.value)
      commentFormData[key].value.data = e.target.value

      if (commentFormData[key].props.error.data) {
        commentFormData[key].validate()
      }
      return
    }

    commentFormData[key].value.data = e.target.checked
    commentFormData[key].value.setData(e.target.checked)

    if (commentFormData[key].props.error.data) {
      commentFormData[key].validate()
    }

  }

  function processCaptchaClick(e, data) {
    if (e) {
      commentFormData.submit.attribute.text.setData("loading...")
      commentFormData.submit.attribute.disabled.setData(true)
      e.preventDefault()

      data = {
        fields: {
          content: commentFormData.comment.value.data,
          path: ThreeMysticApesUtil.TrimURLPathBoth(props.pageNode.slug),
          slug: props.pageNode.slug,
          postId: props.pageNode.id,
        },
        "g-recaptcha-response": "badresponse",
      }
    }

    const dataIsValid = commentFormData.validate()
    if (!dataIsValid) {
      commentFormData.submit.attribute.text.setData("Post Comment")
      commentFormData.submit.attribute.disabled.setData(false)
      return
    }

    if (window) {
      const grecaptcha = window.grecaptcha
      if (grecaptcha == null) {
        setTimeout(function () {
          processCaptchaClick(null, data)
        }, 100)
        return
      }

      grecaptcha.ready(function () {
        grecaptcha
          .execute(recaptchaKey, { action: "submit" })
          .then(function (token) {
            data["g-recaptcha-response"] = token;

            const action = key => (
              <Button
                onClick={() => {
                  closeSnackbar(key)
                }}
              >
                'Dismiss'
              </Button>
            )

            axios({
              method: "post",
              url: staticManSubmit,
              data: data,
              headers: { },
            })
              .then(r => {
                commentFormData.submit.attribute.text.setData("Post Comment")
                commentFormData.submit.attribute.disabled.setData(false)
                commentFormData.reset()
                enqueueSnackbar("Comment Sent", {
                  variant: "success",
                  preventDuplicate: true,
                  autoHideDuration: 3000,
                  action,
                })
              })
              .catch(r => {
                commentFormData.submit.attribute.text.setData("Post Comment")
                commentFormData.submit.attribute.disabled.setData(false)
                enqueueSnackbar(
                  "Comment could not be sent, please try again.",
                  {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 3000,
                    action,
                  }
                )
              })
          })
      })
    }
  }

  return (
    <form id="commentForm" method="POST">
      <GoogleRecaptcha />
      <Card className={classNames(classesPage.card, themeVariation)}>
        <CardHeader color="primary">
          <H4 title="Leave a comment" className="cardTitle">
            Leave a comment
          </H4>
        </CardHeader>
        <CardBody className={classesPage.cardBody}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                label="comment*"
                inputProps={{ "aria-label": "Comment - Required" }}
                id="commentFormComment"
                fullWidth={true}
                multiline={true}
                rows={5}
                variant="filled"
                error={commentFormData.comment.props.error.data}
                onChange={evt => processInputChange(evt, "comment")}
                helperText={commentFormData.comment.props.helperText.data}
                className="contactText"
                value={commentFormData.comment.value.data}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                required
                error={commentFormData.agree.props.error.data}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        commentFormData.agree.value.data
                          .toString()
                          .toLowerCase() === "true"
                      }
                      onChange={evt => processInputChange(evt, "agree")}
                    />
                  }
                  label={
                    <FormHelperText>
                      In order to post your comment you must agree to our
                      Privacy Policy and Terms. You also must acknowledge you
                      understand the following:
                      <br />
                      <small>
                        *Please share what you think about the post, please
                        allow upto 10 minutes for comments to appear. This is a
                        static generated site, so all comments have to be
                        compiled into the site. Please be respectful and have
                        your post help push the conversation.
                      </small>
                    </FormHelperText>
                  }
                />
              </FormControl>
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter className={classNames([classesPage.CardFooter])}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color="primary"
                onClick={processCaptchaClick}
                aria-label="Comment Form Submit"
                disabled={commentFormData.submit.attribute.disabled.data}
                className={classNames("SendButtonJustifyRight")}
              >
                {commentFormData.submit.attribute.text.data}
              </Button>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </form>
  )
}
