import React from "react"
import { graphql } from "gatsby"


import {
  LayoutBlog,
  useLayoutStyles,
  usePageStyles,
  usePostStyles,
} from "@components/layout/layoutBlog"
import MDXRenderer from "@components/layout/section/common/page/mdxRender"

/*
children: 
location: {hash:, host:, hostname:, href, key, orgin, pathname, port, protocol, search, state:{key:}}
navigate: ƒ navigate(to, options)
pageContext: {slug: }
pageResources: {json: {…}, page: {…}, staticQueryResults: {…}, component: ƒ}
params: {}
path: "/about"
pageContext: {slug: "/about"} //Based off gatsby-node create page
uri: "/about"
*/
export default function TemplatePost({ data, pageContext, location, ...props }) {
  
  const { node } = data    

  const classes = useLayoutStyles()
  const classesPage = usePageStyles()
  const classesPost = usePostStyles()


  return (
    <LayoutBlog
      pageNode={node}
      location={location}
      pageContext={pageContext}
      {...props}
    >
      <MDXRenderer
        pageNode={node}
        pageContext={pageContext}
        location={location}
        classes={classes}
        classesPage={classesPage}
        classesPost={classesPost}
      />
    </LayoutBlog>
  )
}

// node:threeMysticApesPost(post: {slug: {eq: $rawSlug}}) {
export const query = graphql`
  query($postID: String!) {
    node: threeMysticApesPost(id: { eq: $postID }) {
      id
      postHash
      slug
      readingTime {
        text
        minutes
        time
        words
      }
      internal {
        content
      }
      context: post {
        title
        tags
        excerpt
        description
        hashTag
        meta {
          content
          name
          property
        }
        mysticApes {
          name
        }
        seriesData {
          series {
            name
            display
          }
          part
        }
        pageStyle {
          name
          style
        }
        hero {
          publicURL
        }
        created
        startDate
        stylePath {
          relativePath
        }
        contentImages {
          name
          images {
            title
            image {
              publicURL
            }
          }
        }
      }
      allowComments
      hasComments
      postComments {
        timestamp
        internal {
          content
        }
      }
      seriesNode {
        id
        slug
        post {
          title
          created
          startDate
          ariaLabel
          excerpt
          seriesData {
            series {
              name
              display
            }
            part
          }
        }
      }
      similarNodeByTag {
        id
        slug
        post {
          title
          created
          startDate
          ariaLabel
          excerpt
        }
      }
      similarNodeByMysticApes {
        id
        slug
        post {
          title
          created
          startDate
          ariaLabel
          excerpt
        }
      }
    }
  }
`