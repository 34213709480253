//copy contents from assets/jss/common/material-kit-react.js
//Dark theme reference
//https://www.bootstrapdash.com/demo/material-admin-dark/jquery/template/demo_1/index.html
//material components
//https://material-components.github.io/


import React, {Fragment} from "react"
import PropTypes from "prop-types";


//templateComponents
import { H1, H4, H6 } from "@components/header/header.js";





PostHeroText.propTypes = {
  pageNode: PropTypes.object.isRequired,
  props: PropTypes.object,
}



export default function PostHeroText({ children, pageNode, ...props }) {

  if (pageNode.context.seriesData === null || typeof pageNode.context.seriesData === 'undefined'){
      return (
        <Fragment>
          <H1 title={pageNode.context.title}>{pageNode.context.title}</H1>
          <H6 title="About">{pageNode.postHash.join(" ")}</H6>
        </Fragment>
      )
  }
 

  return (
    <Fragment>
      <H1 title={pageNode.context.title}>{pageNode.context.title}</H1>
      <H4
        title={`${pageNode.context.seriesData.series.display} Part ${pageNode.context.seriesData.part}`}
      >
        Part {pageNode.context.seriesData.part}
      </H4>
      <H6 title="Hash Tag">{pageNode.postHash.join(" ")}</H6>
    </Fragment>
  )
}