import React from "react"

// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import Filter from 'bad-words-plus';

import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"

// core components
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

import { H4,H6 } from "@components/header/header.js"
import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"


BlogCommentList.propTypes = {
  props: PropTypes.object,
}

export function BlogCommentList(props) {
  const { monkeys } = useStaticQuery(
    graphql`
      query {
        monkeys: allThreeMysticApesMysticApe {
          edges {
            node {
              name
            }
          }
        }
      }
    `
  )

  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation()

  const { pageNode } = props

  if (
    pageNode === null ||
    typeof pageNode === "undefined" ||
    !pageNode.allowComments
  ) {
    return null
  }

  if (!pageNode.hasComments) {
    return <div>No comments</div>
  }

  const filter = new Filter({ firstLetter: true, lastLetter: true })

  return (
    <GridContainer>
      <GridItem xs={10} sm={10} md={10}>
        <H4 title={`Comments`}>Comments:</H4>
        <GridContainer>
          {pageNode.postComments.map((comment, index) => (
            <GridItem xs={12} sm={12} md={12} key={index}>
              <div>
                <H6
                  title={monkeys.edges[Math.floor(Math.random() * 3)].node.name}
                >
                  {monkeys.edges[Math.floor(Math.random() * 3)].node.name}
                </H6>
                <H6
                  title={`Posted: ${ThreeMysticApesUtil.FormatDateTime(
                    comment.timestamp
                  )}`}
                >
                  {ThreeMysticApesUtil.FormatDateTime(comment.timestamp)}
                </H6>
                <div>{filter.clean(comment.internal.content)}</div>
                <hr />
              </div>
            </GridItem>
          ))}
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}
