import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Link } from "gatsby"

import { H6 } from "@components/header/header.js"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

BlogSideNavMysticApes.propTypes = {
  props: PropTypes.object,
}

function GetNodes(pageNode, excludeNodes) {
  if (
    pageNode.similarNodeByMysticApes === null ||
    typeof pageNode.similarNodeByMysticApes === "undefined"
  ) {
    return []
  }

  if (excludeNodes === null || typeof excludeNodes === "undefined") {
    return pageNode.similarNodeByMysticApes
  }
  const filterPosts = pageNode.similarNodeByTag.filter(node => {
    return excludeNodes.indexOf(node.id) === -1
  })

  return filterPosts.slice(0, 5)
}

function GetPartInfo(node) {
  if (node === null || typeof node === "undefined") {
    return ``
  }
  if (node.post === null || typeof node.post === "undefined") {
    return ``
  }
  if (node.post.series === null || typeof node.post.series === "undefined") {
    return ``
  }
  if (isNaN(node.post.series.part)) {
    return ``
  }
  if (node.post.series.part < 1) {
    return ``
  }
  return ` - Part ${node.post.series.part}`
}

export default function BlogSideNavMysticApes(props) {
  const { pageNode, classes, excludeNodes, location } = props
  const displayNodes = GetNodes(pageNode, excludeNodes)

  if (displayNodes.length < 1) {
    return null
  }

  return (
    <div>
      <H6 title="Mystic Apes">Mystic Apes</H6>
      <List className={classes.list}>
        {displayNodes.map((node, index) => (
          <ListItem className={classes.listItem} key={index}>
            <Link
              to={node.slug}
              className={classNames([classes.navLink, "sideNavLink"])}
              role="menuitem"
              title={`${node.post.title}${GetPartInfo(node)}`}
              aria-label={`${node.post.ariaLabel}${GetPartInfo(node)}`}
              state={
                location === null || typeof location === "undefined"
                  ? null
                  : location.state
              }
            >
              {node.post.title}
              {GetPartInfo(node)}
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  )
}
