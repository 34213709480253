import { backgroundColor, primaryColor } from "assets/jss/common/threemysticapes.js"

export const layoutPostStyle = {
  hero: {
    "&.force-light": {
      "& h1, h4, h6": {
        color: backgroundColor.dark.color,
      },
    },
    "& .heroText": {
      position: "absolute",
      left: "15px",
      bottom: "15px",
      textAlign: "left",
    },
  },
  mainContent: {
    width: "calc(100% - 350px)",
    marginLeft: "10px",
    marginRight: "auto",
  },
  blogSideNav: {
    width: "250px",
    marginLeft: "auto",
    marginRight: "10px",
    "&.dark": {
      "& h1,h2,h3,h4,h5,h6": {
        color: backgroundColor.dark.color,
      },
      "& .sideNavLink": {
        color: primaryColor.dark.dark.background,
        "&:hover": {
          color: primaryColor.light.dark.background,
        },
      },
      "& .openClose": {
        backgroundColor: "#FFFFFF",
      },
    },
    "& ul": {
      margin: "0 0",
      padding: "0 0",
    },
    "& ul li": {
      margin: "0 0",
      padding: "0px 10px 10px 15px",
    },
    "& .sideNavLink": {
      fontSize: "0.70rem",
    },
    "& .react-share__ShareButton": {
      marginRight: "5px",
    },
    "& .openClose": {
      fontSize: "3rem",
      position: "absolute",
      left: "-2.2rem",
      top: "0px",
      borderRadius: "5px",
      display: "none",
    },
  },
  "@media screen and (max-width:959px)": {
    blogSideNav: {
      position: "absolute",

      "&.open": {
        transition: "all 150ms ease 0s",
        right: "0px",
      },

      "&.close": {
        transition: "all 150ms ease 0s",
        right: "-275px",
      },

      "& .openClose": {
        display: "block",
        cursor: "pointer",
      },
    },
    mainContent: {
      width: "calc(100% - 50px)",
    },
  },
}

export default {
    layoutPostStyle,
}